import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import Img from "gatsby-image"

const FooPage = ({ data }) => {

  const photos = data.allImageSharp.nodes;

  return (
    <div>
      {photos.map((item, i) => (
          <div className="item" key={item.id}>
            <Img 
              style={{
                width: "160px",
                float: "left",
                margin: "3px"
              }}
              fluid={item.fluid} />
            {/* <div class="location-meta">
                {item.images[0].title}, {item.city}. {item.images[0].date}
              </div> */}
          </div>
        ))
      }
    </div>
  )
}

export const query = graphql`
query MyQuery {
  allImageSharp(filter: {original: {src: {regex: "/banner/"}}}) {
    nodes {
      id
      fluid(maxWidth: 200) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

export default FooPage
